import React, { Component } from "react";
import { Route } from "react-router-dom";

import { computed, observable, makeObservable } from "mobx";
import Header from "../Components/Header";
import "./index.css";
import styled from "styled-components";
import { DuplicateIcon } from "@heroicons/react/outline";

import { UserCircleIcon } from "@heroicons/react/outline";
import MainBody from "../Components/Body";

import { Helmet } from "react-helmet";

import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";

@inject("store")
@observer
class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historyData: [],
      rawInput: {},
      expandedCardIndex: null,
      searchText: "",
    };
  }

  @computed get ifNotActive() {
    if (this.props.store.profile.cancel_at_period_end) {
      return "Canceled";
    }
    if (this.props.store.profile.status === "trialing") {
      return "Trialing";
    }
    return false;
  }

  @observable plan = {
    plan: "",
  };

  componentDidMount() {
    this.props.store.refreshTokenAndProfile();
    this.getHistory();
    makeObservable(this);
    this.init();
  }

  init = async () => {
    // let res = await this.props.store.api.get("/history");
    // this.history = {
    //   ...res.data,
    // };
    // console.log(`this.history`, { ...this.history });
  };

  getHistory = async () => {
    //console.log(this.props.store);

    await this.props.store.api
      .get("/history", {
        params: { user: this.props.store.profile._id },
      })
      .then((res) => {
        this.setState({ historyData: res.data });
      });

    // console.log(this.state.historyData);
  };

  handleCardClick = (index) => {
    this.setState({ expandedCardIndex: index });
  };

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {},
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  handleSearch = (e) => {
    this.setState({ searchText: e.target.value });
  };

  handleDelete = async (id, e) => {
    e.stopPropagation();

    try {
      const response = await this.props.store.api.delete(`/history/${id}`);
      if (response.status === 200) {
        this.setState((prevState) => ({
          historyData: prevState.historyData.filter((tool) => tool._id !== id),
        }));
      } else {
        console.error("Failed to delete data");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  render() {
    const filteredHistoryData = this.state.historyData.filter(
      (tool) =>
        tool.output &&
        tool.output.trim() !== "" &&
        tool.output.toLowerCase().includes(this.state.searchText.toLowerCase())
    );

    return (
      <>
        <Header
          title={this.props.store.profile.email}
          desc={`${this.props.store.profile.fname} ${this.props.store.profile.lname}`}
          category="Dein Profil"
          Icon={UserCircleIcon}
          fromColor={this.fromColor}
        >
          <Route exact path="/history">
            <Helmet>
              <title>{`Historie - AnschreibenAI`}</title>
            </Helmet>
          </Route>
        </Header>

        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12">
          <Title title="Historie" />

          <Input
            type="search"
            id="q"
            name="q"
            className="py-4 pl-4 md:pl-14 text-xl focus:outline-none focus:bg-white focus:text-gray-900 transition flex flex-1 w-full"
            placeholder="Durchsuche historie..."
            autoComplete="off"
            value={this.state.searchText}
            onChange={this.handleSearch}
            onKeyUp={this.onKeyUp}
          />

          {filteredHistoryData.length ? (
            <>
              <div className="cards">
                {filteredHistoryData.map((history, index) => (
                  // <div className="flex relative">
                  <div
                    style={{}}
                    key={index}
                    className={`card bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  hover:text-black ${
                      this.state.expandedCardIndex === index ? "expanded" : ""
                    }`}
                    onClick={() => this.handleCardClick(index)}
                  >
                    <div className="p-4">
                      <div
                        className={`uppercase tracking-wide text-sm text-green-500
                          font-semibold leading-none`}
                      >
                        {new Date(history.created).toLocaleDateString(
                          undefined,
                          {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                      </div>

                      <div
                        href="#"
                        className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none"
                      >
                        {history.companyName || ""}
                      </div>

                      <p className="mt-1 pr-1 text-sm ">
                        <div className="card-body" style={{}}>
                          <div className="output">{history.output}</div>
                          {this.state.expandedCardIndex === index && (
                            <div className="buttons-wrapper">
                              <Shortcut
                                className="btn p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative group flex flex-col items-center group text-gray-300"
                                onClick={() =>
                                  this.copyToClipboard(history.output)
                                }
                              >
                                <DuplicateIcon className="w-5 h-5" />
                                <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                                  <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                    Copy text to clipboard
                                  </span>
                                </Tooltip>
                              </Shortcut>

                              <button
                                className="icon-button delete"
                                onClick={(e) =>
                                  this.handleDelete(history._id, e)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M3 6h18" />
                                  <path d="M9 6V4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2m4 0v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6z" />
                                </svg>
                              </button>
                            </div>
                          )}
                        </div>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </MainBody>
      </>
    );
  }
}

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
    overflow: visible;
  }
`;

const Input = styled.input`
  margin-bottom: 1em;
  border-radius: 10px;
`;

export default withRouter(Body);

export const Divider = () => (
  <div className="divide-y-2 divide-dashed divide-gray-300 py-8 md:py-12">
    {" "}
    <div></div>
    <div></div>
  </div>
);

export const Title = ({ title }) => (
  <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">
    {title}
  </h2>
);
